define("client/components/ol-map-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    olmap2svc: Ember.inject.service('ol-mapservice'),
    didInsertElement: function didInsertElement() {
      var olmap2svc = this.get('olmap2svc');
      var coordinates = this.get('coordinates');
      console.log("Inside ol map point component, coordinates : ");
      console.log(coordinates);
      var map = this.get('map');
      console.log("Inside ol map point component, map is: " + map); //const initialExtent = this.get('initialExtent')
      //if (initialExtent) map.getView().fit(initialExtent, map.getSize())

      if (coordinates) {
        // check further whether set points array still needed
        olmap2svc.setPointsArrayDab(coordinates);
        olmap2svc.plotOnePoint(map, coordinates);
      }
      /* if (coordinates) {
        console.log("typeof coordinates " + typeof coordinates);
        console.log("is coordinates an array " + Array.isArray(coordinates));
        console.log(coordinates);
        olmap2svc.plotManyPoints(map,coordinates);
        
      } */

    }
  });

  _exports.default = _default;
});