define("client/pods/components/w3o-users-roles/user-profile/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    profilepic: '',
    dataURI: '',
    accessHeader: '',
    isUploading: false,
    oldPasswordIsEmpty: null,
    newPasswordIsEmpty: null,
    passwordRepeatIsEmpty: null,
    newPasswordIsNotSameWithRepeat: null,
    newPasswordSameAsOld: null,
    passwordError: null,
    oldPasswordError: null,
    siteAdmin: false,
    //change this to variable that determine the user role
    authObject: Ember.inject.service('session'),
    getInfoDebouncer: function getInfoDebouncer(param) {
      this.getInfo(param);
    },
    actions: {
      //change isEditing to true
      editProfile: function editProfile() {
        this.set('isEditing', true);
      },
      setStatus: function setStatus(value) {
        var user = this.get('user');
        console.log('value');
        console.log(value);
        user.set('status', value);
        user.save();
      },
      changeStatus: function changeStatus() {
        var user = this.get('model');
        this.changeStatus(user);
      },
      getInfo: function getInfo(param) {
        Ember.run.debounce(this, this.getInfoDebouncer, param, 800);
      },
      checkFirstName: function checkFirstName() {
        if (Ember.isEmpty(this.get('model').get('firstName'))) {
          (0, _jquery.default)("#firstNameForm").addClass("has-error");
          (0, _jquery.default)("#firstNameIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphFirstName").removeClass("d-none");
        }
      },
      checkLastName: function checkLastName() {
        if (Ember.isEmpty(this.get('model').get('lastName'))) {
          (0, _jquery.default)("#lastNameForm").addClass("has-error");
          (0, _jquery.default)("#lastNameIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphLastName").removeClass("d-none");
        }
      },
      checkEmail: function checkEmail() {
        var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        if (!/\S/.test(this.get('model').get('email')) || !this.get('model').get('email')) {
          (0, _jquery.default)("#emailForm").addClass("has-error");
          (0, _jquery.default)('#glyphEmail').removeClass('d-none');
          (0, _jquery.default)('#emailError').removeClass('d-none');
          (0, _jquery.default)('#emailError2').addClass('d-none');
        } else if (!regexEmail.test(this.get('model').get('email')) || !this.get('model').get('email')) {
          (0, _jquery.default)("#emailForm").addClass("has-error");
          (0, _jquery.default)('#glyphEmail').removeClass('d-none');
          (0, _jquery.default)('#emailError2').removeClass('d-none');
          (0, _jquery.default)('#emailError').addClass('d-none');
        }
      },
      checkPostalCode: function checkPostalCode() {
        if (Ember.isEmpty(this.get('model').get('postalCode'))) {
          (0, _jquery.default)("#postalCodeForm").addClass("has-error");
          (0, _jquery.default)("#postalCodeIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphPostalCode").removeClass("d-none");
        }
      },
      checkAddress: function checkAddress() {
        if (Ember.isEmpty(this.get('model').get('address'))) {
          (0, _jquery.default)("#addressForm").addClass("has-error");
          (0, _jquery.default)("#addressIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphAddress").removeClass("d-none");
        }

        if (Ember.isEmpty(this.get('model').get('address1'))) {
          (0, _jquery.default)("#address1Form").addClass("has-error");
          (0, _jquery.default)("#address1IsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphAddress1").removeClass("d-none");
        }
      },
      checkCity: function checkCity() {
        if (Ember.isEmpty(this.get('model').get('city'))) {
          (0, _jquery.default)("#cityForm").addClass("has-error");
          (0, _jquery.default)("#cityIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphCity").removeClass("d-none");
        }
      },
      checkState: function checkState() {
        if (Ember.isEmpty(this.get('model').get('state'))) {
          (0, _jquery.default)("#stateForm").addClass("has-error");
          (0, _jquery.default)("#stateIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphState").removeClass("d-none");
        }
      },
      checkCountry: function checkCountry() {
        if (Ember.isEmpty(this.get('model').get('postalCode'))) {
          (0, _jquery.default)("#postalCodeForm").addClass("has-error");
          (0, _jquery.default)("#postalCodeIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphPostalCode").removeClass("d-none");
        }
      },
      checkPhone: function checkPhone() {
        if (Ember.isEmpty(this.get('model').get('phone'))) {
          (0, _jquery.default)("#phoneForm").addClass("has-error");
          (0, _jquery.default)("#phoneIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphPhone").removeClass("d-none");
        }
      },
      checkInitial: function checkInitial() {
        if (Ember.isEmpty(this.get('model').get('initial'))) {
          (0, _jquery.default)("#initialNameForm").addClass("has-error");
          (0, _jquery.default)("#initialNameIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphinitialName").removeClass("d-none");
        }
      },
      saveEdit: function saveEdit() {
        var user = this.get('model');
        var flag = 0;
        var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        if (Ember.isEmpty(user.get('firstName'))) {
          (0, _jquery.default)("#firstNameForm").addClass("has-error");
          (0, _jquery.default)("#firstNameIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphFirstName").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('lastName'))) {
          (0, _jquery.default)("#lastNameForm").addClass("has-error");
          (0, _jquery.default)("#lastNameIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphLastName").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('postalCode'))) {
          (0, _jquery.default)("#postalCodeForm").addClass("has-error");
          (0, _jquery.default)("#postalCodeIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphPostalCode").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('address'))) {
          (0, _jquery.default)("#addressForm").addClass("has-error");
          (0, _jquery.default)("#addressIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphAddress").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('address1'))) {
          (0, _jquery.default)("#address1Form").addClass("has-error");
          (0, _jquery.default)("#address1IsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphAddress1").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('city'))) {
          (0, _jquery.default)("#cityForm").addClass("has-error");
          (0, _jquery.default)("#cityIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphCity").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('state'))) {
          (0, _jquery.default)("#stateForm").addClass("has-error");
          (0, _jquery.default)("#stateIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphState").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('country.content'))) {
          (0, _jquery.default)("#countryCodeForm").addClass("has-error");
          (0, _jquery.default)("#countryCodeIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphCountryCode").removeClass("d-none");
          flag++;
        }
        /*else if (isNaN(user.get('countryCode'))){
            $( "#countryCodeForm" ).addClass( "has-error" );
            $( "#countryCodeIsNaN" ).removeClass( "d-none" );
            $( "#glyphCountryCode" ).removeClass( "d-none" );
            flag++;
        }*/


        if (Ember.isEmpty(user.get('phone'))) {
          (0, _jquery.default)("#phoneForm").addClass("has-error");
          (0, _jquery.default)("#phoneIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphPhone").removeClass("d-none");
          flag++;
        }

        if (!/\S/.test(user.get('email')) || !user.get('email')) {
          (0, _jquery.default)("#emailForm").addClass("has-error");
          (0, _jquery.default)('#glyphEmail').removeClass('d-none');
          (0, _jquery.default)('#emailError').removeClass('d-none');
          (0, _jquery.default)('#emailError2').addClass('d-none');
          flag++;
          user.set('email', '');
        } else if (!regexEmail.test(user.get('email')) || !user.get('email')) {
          (0, _jquery.default)("#emailForm").addClass("has-error");
          (0, _jquery.default)('#glyphEmail').removeClass('d-none');
          (0, _jquery.default)('#emailError2').removeClass('d-none');
          (0, _jquery.default)('#emailError').addClass('d-none');
          flag++;
          user.set('email', '');
        } else if (!user.get('initial') || user.get('initial').length > 5) {
          (0, _jquery.default)("#initialNameForm").addClass("has-error");
          (0, _jquery.default)('#glyphinitialName').removeClass('d-none');
          (0, _jquery.default)('#initialNameError2').removeClass('d-none');
          (0, _jquery.default)('#initialNameError').addClass('d-none');
          flag++;
        }

        if (flag === 0) {
          this.saveEdit(user);
        }
      },
      //set isEditing to false
      cancelEdit: function cancelEdit() {
        this.set('isEditing', false);
        this.set('isUploading', false);
        this.set('profilepic', '');
        this.set('dataURI', '');
      },
      queuePhoto: function queuePhoto(file) {
        var self = this;
        this.set('isUploading', true);
        this.set('profilepic', file);
        file.read().then(function setPreviewUrl(dataURI) {
          self.set('dataURI', dataURI);
        });
      },
      changePassword: function changePassword() {
        var model = this.get('model');
        var flag = 0;
        /*
            Regex for password
            at least 8 characters
            at least contains 1 uppercase, 1 lowercase, 1 number, and 1 special character( !"#$%&'()*+,-.:;<=>/?@\^[]_`{}|~ no space currently) 
        */

        var regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~])[a-zA-Z\d!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~]{8,}$/;

        if (Ember.isEmpty(model.get('oldpassword')) && !this.get('canReset')) {
          (0, _jquery.default)("#oldForm").addClass("has-error");
          (0, _jquery.default)('#glyphOld').removeClass('d-none');
          (0, _jquery.default)('#oldError').removeClass('d-none');
          (0, _jquery.default)('#oldError2').addClass('d-none');
          flag++; // model.set('oldpassword', null);
        } else {
          (0, _jquery.default)("#oldForm").removeClass("has-error");
          (0, _jquery.default)('#glyphOld').addClass('d-none');
          (0, _jquery.default)('#oldError').addClass('d-none');
          (0, _jquery.default)('#oldError2').addClass('d-none');
        }

        if (Ember.isEmpty(model.get('newpassword'))) {
          (0, _jquery.default)("#newForm").addClass("has-error");
          (0, _jquery.default)('#glyphNew').removeClass('d-none');
          (0, _jquery.default)('#newError').removeClass('d-none');
          (0, _jquery.default)('#newError2').addClass('d-none');
          (0, _jquery.default)('#newError3').addClass('d-none');
          flag++; // model.set('newpassword', null);
        } else if (!regexPass.test(model.get('newpassword'))) {
          (0, _jquery.default)("#newForm").addClass("has-error");
          (0, _jquery.default)('#glyphNew').removeClass('d-none');
          (0, _jquery.default)('#newError3').removeClass('d-none');
          (0, _jquery.default)('#newError').addClass('d-none');
          (0, _jquery.default)('#newError2').addClass('d-none');
          flag++;
        } else if (model.get('newpassword') === model.get('oldpassword') && !this.get('canReset')) {
          (0, _jquery.default)("#newForm").addClass("has-error");
          (0, _jquery.default)('#glyphNew').removeClass('d-none');
          (0, _jquery.default)('#newError2').removeClass('d-none');
          (0, _jquery.default)('#newError').addClass('d-none');
          (0, _jquery.default)('#newError3').addClass('d-none');
          flag++; // model.set('newpassword', null);
        } else {
          (0, _jquery.default)("#newForm").removeClass("has-error");
          (0, _jquery.default)('#glyphNew').addClass('d-none');
          (0, _jquery.default)('#newError').addClass('d-none');
          (0, _jquery.default)('#newError2').addClass('d-none');
          (0, _jquery.default)('#newError3').addClass('d-none');
        }

        if (Ember.isEmpty(model.get('passwordRepeat'))) {
          (0, _jquery.default)("#repeatForm").addClass("has-error");
          (0, _jquery.default)('#glyphRepeat').removeClass('d-none');
          (0, _jquery.default)('#repeatError').removeClass('d-none');
          (0, _jquery.default)('#repeatError2').addClass('d-none');
          flag++; // model.set('passwordRepeat', null);
        } else if (model.newpassword !== model.passwordRepeat) {
          (0, _jquery.default)("#repeatForm").addClass("has-error");
          (0, _jquery.default)('#glyphRepeat').removeClass('d-none');
          (0, _jquery.default)('#repeatError2').removeClass('d-none');
          (0, _jquery.default)('#repeatError').addClass('d-none');
          flag++; // model.set('passwordRepeat', null);
        } else {
          (0, _jquery.default)("#repeatForm").removeClass("has-error");
          (0, _jquery.default)('#glyphRepeat').addClass('d-none');
          (0, _jquery.default)('#repeatError').addClass('d-none');
          (0, _jquery.default)('#repeatError2').addClass('d-none');
        }

        if (flag === 0) {
          this.changePassword(model);
        }
      },
      changeQuestion: function changeQuestion() {
        console.log('changeQuestion');
        var model = this.get('model');
        var flag = 0; //validation

        console.log(Ember.isEmpty(model.get('currentPassword')));
        console.log(!this.get('canReset'));

        if (Ember.isEmpty(model.currentPassword) && !this.get('canReset')) {
          (0, _jquery.default)("#currentPasswordForm").addClass("has-error");
          (0, _jquery.default)('#glyphcurrentPassword').removeClass('d-none');
          (0, _jquery.default)('#currentPasswordIsEmpty').removeClass('d-none');
          (0, _jquery.default)('#currentPasswordIsNotSame').addClass('d-none');
          flag++;
        }

        if (Ember.isEmpty(model.get('newAnswer'))) {
          (0, _jquery.default)("#newanswerForm").addClass("has-error");
          (0, _jquery.default)('#glyphNewanswer').removeClass('d-none');
          (0, _jquery.default)('#newanswerError').removeClass('d-none');
          (0, _jquery.default)('#newanswerError2').addClass('d-none');
          flag++;
          model.set('newAnswer', null);
        }

        if (Ember.isEmpty(model.get('answerRepeat'))) {
          (0, _jquery.default)("#repeatanswerForm").addClass("has-error");
          (0, _jquery.default)('#glyphRepeatanswer').removeClass('d-none');
          (0, _jquery.default)('#repeatanswerError').removeClass('d-none');
          (0, _jquery.default)('#repeatanswerError2').addClass('d-none');
          flag++;
          model.set('answerRepeat', null);
        } else if (model.get('newAnswer') !== model.get('answerRepeat')) {
          (0, _jquery.default)("#repeatanswerForm").addClass("has-error");
          (0, _jquery.default)('#glyphRepeatanswer').removeClass('d-none');
          (0, _jquery.default)('#repeatanswerError2').removeClass('d-none');
          (0, _jquery.default)('#repeatanswerError').addClass('d-none');
          flag++;
          model.set('answerRepeat', null);
        }

        if (Ember.isEmpty(model.get('newQuestion'))) {
          (0, _jquery.default)("#newquestionForm").addClass("has-error");
          (0, _jquery.default)('#glyphNewquestion').removeClass('d-none');
          (0, _jquery.default)('#newquestionError').removeClass('d-none');
          (0, _jquery.default)('#newquestionError2').addClass('d-none');
          flag++;
          model.set('newQuestion', null);
        } else {
          (0, _jquery.default)("#newquestionForm").removeClass("has-error");
          (0, _jquery.default)('#glyphNewquestion').addClass('d-none');
          (0, _jquery.default)('#newquestionError').addClass('d-none');
          (0, _jquery.default)('#newquestionError2').addClass('d-none');
        }

        if (flag === 0) {
          this.changeQuestion(model);
        }
      },
      unblockUser: function unblockUser(param) {
        this.unblockUser(param);
      }
    }
  });

  _exports.default = _default;
});