define("client/pods/components/w3o-users-roles/form-user/username/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0sj8jxgh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"username\"],[8],[0,\"Username \"],[7,\"span\",true],[10,\"style\",\"color: red\"],[8],[0,\"*\"],[9],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"usernameIsEmpty\"],[8],[0,\"Username cannot be empty\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"d-none pull-right red\"],[10,\"id\",\"usernameRegexError\"],[8],[0,\"Username must contains maximum 30 characters of alphabets, numeric, or special characters (_.).\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right-inner-addon\"],[10,\"id\",\"usernameForm\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"bottom: 0;\"],[10,\"class\",\"fa fa-times-circle text-danger d-none margin-right--4\"],[10,\"id\",\"glyphUsername\"],[8],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"value\"],[\"username\",\"Enter Your Username\",\"form-control\",[24,[\"model\",\"username\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/form-user/username/template.hbs"
    }
  });

  _exports.default = _default;
});