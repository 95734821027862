define("client/pods/components/w3office-forms/standard-components/check-box/component", ["exports", "client/mixins/w3office-forms/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    /*
    * Array of Checked boxes
    */
    checkedContainer: Ember.A(),

    /*
    * Array for storing checkbox options
    */
    fieldContents: Ember.computed('field.fieldContents', function () {
      return this.get('field').get('fieldContents');
    }),
    isLoadFieldContents: Ember.computed('field.loadFieldContents', function () {
      return this.get('field').get('loadFieldContents');
    }),
    isSkeleton: Ember.computed('isLoadFieldContents', function () {
      if (this.get('isLoadFieldContents')) {
        return true;
      } else {
        return false;
      }
    }),

    /*
    * Populate fieldcontents with value from fieldgroupcontent
    * Determine which value are checked by comparing value from fieldgroupcontents with entryContainer
    */
    init: function init() {
      this._super();

      if (this.get("authoring")) {
        this.set("exampleFieldContents", [{
          name: 'Option 1'
        }, {
          name: 'Option 2'
        }, {
          name: 'Option 3'
        }, {
          name: 'Option 4'
        }]);
      } else {
        this.get('field').setProperties({
          loadFieldContents: true
        });

        if (!Ember.isEmpty(this.get('field'))) {
          this.fieldActionGateway(this, {
            actionName: 'getFieldGroupContent'
          });
        }
      }
    },
    //	didInsertElement() {
    //		var el = document.querySelector('.customScrollbar');
    //		Ps.initialize(el);
    //	},
    actions: {
      mutateEntry: function mutateEntry(val, checked) {
        if (checked) {
          this.get('checkedContainer').addObject(val);
        } else {
          this.get('checkedContainer').removeObject(val);
        }

        this.setEntryContainer(this.get('field'), this.get('checkedContainer'));
      }
    }
  });

  _exports.default = _default;
});