define("client/pods/users-roles/groups/index/route", ["exports", "client/mixins/w3o-users-roles/check-user-role", "axios"], function (_exports, _checkUserRole, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    userId: '',
    groupCon: '',
    groupName: '',
    actionList: null,
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      var Variable = Ember.Object.extend({
        action: null,
        varName: null,
        status: false
      });
      var actions = [Variable.create({
        action: 'Edit Group',
        varName: 'canEditGroup',
        status: false
      }), Variable.create({
        action: 'Delete Group',
        varName: 'canDeleteGroup',
        status: false
      })];
      this.set('actionList', actions);

      this._super();
    },
    model: function model() {
      return this.store.findAll('group');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.toArray());
      controller.set('group', null);
      controller.set('groupName', null);
      controller.set('listPage', 1);
      controller.set('listPageModal', 1);
      controller.set('totalPage', 1);
      controller.set('totalPageModal', 1);
      controller.set('limit', 10);
      controller.set('limitModal', 10);
      controller.set('sortBy', '"fullName"');
      controller.set('sortOrder', 'ASC');
      controller.set('sortByModal', '"fullName"');
      controller.set('sortOrderModal', 'ASC');
      controller.set('searchInput', '');
      controller.set('searchColumn', '"fullName"');
      controller.set('searchInputModal', '');
      controller.set('searchColumnModal', '"fullName"');
      controller.set('createGroup', false);
      controller.set('hasSelect', false);
      controller.set('canCreateGroup', false);
      controller.set('canEditGroup', false);
      controller.set('canDeleteGroup', false);
      this.get('setActions').forEach(function (action) {
        if (action.get('varName') === 'canCreateGroup') {
          controller.set('canCreateGroup', action.get('status'));
        }

        if (action.get('varName') === 'canEditGroup') {
          controller.set('canEditGroup', action.get('status'));
        }

        if (action.get('varName') === 'canDeleteGroup') {
          controller.set('canDeleteGroup', action.get('status'));
        }
      });
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    observeVariable: Ember.observer('setActions.[]', function () {
      var controller = this.get('controller');

      if (controller) {
        this.get('setActions').forEach(function (action) {
          if (action.get('varName') === 'canCreateGroup') {
            controller.set('canCreateGroup', action.get('status'));
          }

          if (action.get('varName') === 'canEditGroup') {
            controller.set('canEditGroup', action.get('status'));
          }

          if (action.get('varName') === 'canDeleteGroup') {
            controller.set('canDeleteGroup', action.get('status'));
          }
        });
      }
    }),
    getUserFromSelectedGroup: function getUserFromSelectedGroup() {
      var controller = this.get('controller');
      controller.set("showLoadingUser", true);
      var axiosConfig = {
        method: "GET",
        params: {
          groupId: this.get('groupCon.id'),
          page: controller.get('listPage'),
          limit: controller.get('limit'),
          sortBy: controller.get('sortBy'),
          sortOrder: controller.get('sortOrder'),
          searchInput: controller.get('searchInput'),
          searchColumn: controller.get('searchColumn')
        },
        url: this.store.adapterFor('application').get('namespace') + '/rolegroups/getUserFromSelectedGroup'
      };
      (0, _axios.default)(axiosConfig).then(function (response) {
        if (response.status === 200) {
          var temp = Ember.A();

          if (response.data.assignedUser.length) {
            // response.data.assignedUser.forEach( (user) => {
            //     this.store.findRecord('user', user.id).then( (newUser) => {
            //         temp.pushObject(newUser);
            //         controller.set('userGroupModel', temp);
            //     });
            // });
            controller.set('userGroupModel', response.data.assignedUser);
          } else {
            controller.set('userGroupModel', temp);
          }

          if (response.data.exceedTotal) {
            controller.set('listPage', 1);
          }

          controller.set('totalPage', response.data.totalPage);
          controller.set("showLoadingUser", false);
        }
      }).catch(function (err) {
        console.log("ERROR while getUserFromSelectedGroup");
        console.log(err);
        controller.set("showLoadingUser", false);
      });
    },
    actions: {
      setGroup: function setGroup(param) {
        this.set('controller.userGroupModel', '');
        this.set('controller.unassignedUserModel', '');

        if (param !== '') {
          this.set('controller.createGroup', false);
          this.set('controller.hasSelect', true);
          this.set('groupCon', param);
          this.set('controller.groupName', param.get('groupName'));
          this.getUserFromSelectedGroup();
        } else {
          this.set('controller.createGroup', false);
          this.set('controller.hasSelect', false);
        }
      },
      showCreateGroup: function showCreateGroup() {
        this.set('controller.groupName', null);
        this.set('controller.createGroup', true);
      },
      doEdit: function doEdit() {
        this.set('controller.editGroup', true);
      },
      editGroup: function editGroup(param) {
        var self = this;
        var model = this.get('groupCon');
        model.set('groupName', param);
        model.save().then(function () {
          self.set('controller.editGroup', false);
          self.set('controller.groupName', param);
          self.set('controller.model', self.store.findAll('group'));
        });
      },
      cancelEditGroup: function cancelEditGroup() {
        this.set('controller.editGroup', false);
      },
      deleteGroup: function deleteGroup() {
        var _this = this;

        var controller = this.get('controller');
        var axiosConfig = {
          method: "POST",
          data: {
            groupId: this.get('groupCon.id')
          },
          url: this.store.adapterFor('application').get('namespace') + '/groups/deleteGroup'
        };
        (0, _axios.default)(axiosConfig).then(function (response) {
          if (response.status === 200) {
            controller.set('createGroup', false);
            controller.set('hasSelect', false);
            controller.set('group', null);
            controller.set('groupName', null);
            controller.get('model').removeObject(_this.get('groupCon')); // remove selected group from store (ember data)

            _this.get("groupCon").unloadRecord();

            _this.set("groupCon", null);
          }
        }).catch(function (err) {
          console.log("ERROR, while deleteGroup");
          console.log(err);
        });
      },
      doSort: function doSort(param) {
        var controller = this.get('controller');

        if (controller.get('sortBy') !== param) {
          controller.set('sortBy', param);
          controller.set('sortOrder', 'ASC');
        } else {
          if (controller.get('sortOrder') === 'DESC') {
            controller.set('sortOrder', 'ASC');
          } else {
            controller.set('sortOrder', 'DESC');
          }
        }

        this.getUserFromSelectedGroup();
      },
      changeColumn: function changeColumn(param) {
        var controller = this.get('controller');
        controller.set('searchColumn', param);
      },
      doSearch: function doSearch() {
        /*if(!this.get('mobile')){
              var controller = this.get('controller');
               controller.set('processList', Ember.A());
        }*/
        this.getUserFromSelectedGroup();
      },
      changeLimit: function changeLimit(param) {
        this.controller.set("limit", param);
        this.getUserFromSelectedGroup();
      },
      prevList: function prevList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') - 1);
        this.getUserFromSelectedGroup();
      },
      nextList: function nextList() {
        var controller = this.get('controller');
        controller.set('listPage', controller.get('listPage') + 1);
        this.getUserFromSelectedGroup();
      },
      doSortModal: function doSortModal(param) {
        var controller = this.get('controller');

        if (controller.get('sortByModal') !== param) {
          controller.set('sortByModal', param);
          controller.set('sortOrderModal', 'ASC');
        } else {
          if (controller.get('sortOrderModal') === 'DESC') {
            controller.set('sortOrderModal', 'ASC');
          } else {
            controller.set('sortOrderModal', 'DESC');
          }
        }

        this.send('getUnassignedUserFromSelectedGroup');
      },
      changeColumnModal: function changeColumnModal(param) {
        var controller = this.get('controller');
        controller.set('searchColumnModal', param);
      },
      doSearchModal: function doSearchModal() {
        /*if(!this.get('mobile')){
              var controller = this.get('controller');
               controller.set('processList', Ember.A());
        }*/
        this.send('getUnassignedUserFromSelectedGroup');
      },
      changeLimitModal: function changeLimitModal() {
        this.send('getUnassignedUserFromSelectedGroup');
      },
      prevListModal: function prevListModal() {
        var controller = this.get('controller');
        controller.set('listPageModal', controller.get('listPageModal') - 1);
        this.send('getUnassignedUserFromSelectedGroup');
      },
      nextListModal: function nextListModal() {
        var controller = this.get('controller');
        controller.set('listPageModal', controller.get('listPageModal') + 1);
        this.send('getUnassignedUserFromSelectedGroup');
      },
      getUnassignedUserFromSelectedGroup: function getUnassignedUserFromSelectedGroup() {
        var controller = this.get('controller');
        controller.set("showLoadingGroup", true);
        var axiosConfig = {
          method: "GET",
          params: {
            groupId: this.get('groupCon.id'),
            page: controller.get('listPageModal'),
            limit: controller.get('limitModal'),
            sortBy: controller.get('sortByModal'),
            sortOrder: controller.get('sortOrderModal'),
            searchInput: controller.get('searchInputModal'),
            searchColumn: controller.get('searchColumnModal')
          },
          url: this.store.adapterFor('application').get('namespace') + '/rolegroups/getUnassignedUserFromSelectedGroup'
        };
        (0, _axios.default)(axiosConfig).then(function (response) {
          if (response.status === 200) {
            controller.set('unassignedUserModel', response.data.unassignedUser);

            if (response.data.exceedTotal) {
              controller.set('listPageModal', 1);
            }

            controller.set('totalPageModal', response.data.totalPage);
            controller.set("showLoadingGroup", false);
          }
        }).catch(function (err) {
          console.log("ERROR whie getUnassignedUserFromSelectedGroup");
          controller.set("showLoadingGroup", false);
        });
      },
      unassignUser: function unassignUser(param) {
        var _this2 = this;

        var axiosConfig = {
          method: "POST",
          data: {
            groupId: this.get('groupCon.id'),
            userId: param
          },
          url: this.store.adapterFor('application').get('namespace') + '/rolegroups/unassignUser'
        };
        (0, _axios.default)(axiosConfig).then(function (response) {
          if (response.status === 200) {
            _this2.getUserFromSelectedGroup();
          }
        }).catch(function (err) {
          console.log("ERROR: something wrong with unassignUser");
          console.log(err);
        });
      },
      assignUser: function assignUser(param) {
        var _this3 = this;

        var axiosConfig = {
          method: "POST",
          data: {
            usergroup: {
              user: param.id,
              group: parseInt(this.get('groupCon').id, 10)
            }
          },
          url: this.store.adapterFor('application').get('namespace') + '/usergroups'
        };
        console.log(axiosConfig);
        (0, _axios.default)(axiosConfig).then(function (response) {
          if (response.status === 201) {
            _this3.getUserFromSelectedGroup();

            _this3.send('getUnassignedUserFromSelectedGroup');
          }
        }).catch(function (err) {
          console.log("ERROR: something wrong with assignUser");
          console.log(err);
        });
      }
    }
  });

  _exports.default = _default;
});